import {EventsEmitter} from '../../events-emitter.js';
import {Store} from '../../store.js';
import {DialogView} from "./views/dialog-view.js";
import {MapView} from "./views/map-view.js";
import {PointsProvider} from "./providers/points-provider.js";
import {AVAILABLE_PROVIDERS, pointNameFormatter} from './providers.js';
import {baseUrl, defaultCountry} from "./config.js";
import {findMaxZIndex} from "../../../admin/script/util/browser.js";
import countiesCenterCoordinates from "./counties-center-coordinates.js";

export class PointsMap {

    get state() {
        return this.store.get('pointsMap', {});
    }

    set state(nextState) {
        this.store.merge('pointsMap', nextState);
    }

    /**
     * @param {object} props
     */
    constructor(props = {}) {
        this.token = props.token;
        this.baseUrl = props.baseUrl || baseUrl;
        this.store = props.store && props.store instanceof Store ? props.store : new Store();
        const country = String(props.country || defaultCountry).toUpperCase();
        const [defaultLatitude, defaultLongitude] = countiesCenterCoordinates[country] || countiesCenterCoordinates[defaultCountry];
        this.state = {
            searchQuery: props.searchQuery || '',
            availableProviders: props.providers || AVAILABLE_PROVIDERS,
            selectedProviders: props.selectedProviders || props.providers || AVAILABLE_PROVIDERS,
            functions: props.functions || [],
            latitude: Number(props.latitude) || defaultLatitude,
            longitude: Number(props.longitude) || defaultLongitude,
            zoom: Number(props.zoom) || 13,
            showSelectButton: props.showSelectButton !== false,
            showList: props.showList !== false,
            showSearchInput: props.showSearchInput !== false,
            country,
        };

        this.store.addEventListener(this, {
            'change:pointsMap': currentPoint => {
                this.events.trigger('select:point', currentPoint)
            }
        });

        this.pointsProvider = new PointsProvider({
            store: this.store,
            baseUrl: this.baseUrl,
            token: this.token,
        });
        this.events = new EventsEmitter();
    }

    /**
     * Wyświetla mapkę we wskazanym elemencie HTML
     * @returns {PointsMap}
     */
    showMapView(targetElement) {
        if (this.dialog || this.mapView) {
            return this;
        }
        this.mapView = new MapView({
            $el: targetElement,
            store: this.store,
            events: this.events,
            pointsProvider: this.pointsProvider,
            token: this.token,
            baseUrl: this.baseUrl,
        });

        this.mapView.render();
        return this;
    }

    /**
     * Otwiera okno z mapką
     * @returns {PointsMap}
     */
    showMapDialog() {
        if (this.dialog) {
            return this;
        }
        this.mapView = new MapView({
            store: this.store,
            events: this.events,
            pointsProvider: this.pointsProvider,
            token: this.token,
            baseUrl: this.baseUrl,
        });
        this.dialog = new DialogView({
            fullScreen: true,
        });
        this.dialog.open();
        this.dialog.$modal.style.zIndex = findMaxZIndex() + 1000;
        this.dialog.$modal.classList.add('pk-body', 'pk-points-map-modal');
        this.dialog.$body.classList.add('pk-p-0');
        this.dialog.$closeButton.classList.add('pk-bg-pk', 'pk-bg-pk-hover', 'pk-text-bold', 'pk-text-white', 'pk-border-0', ',pk-cursor-pointer');
        this.dialog.showView(this.mapView);

        this.dialog.onClose(() => {
            this.dialog = null;
            this.mapView = null;
            this.events.trigger('dialog:closed');
        });

        this.dialog.onOpen(() => {
            this.events.trigger('dialog:open');
        });

        return this;
    }

    /**
     * Zamyka okno z mapką
     * @returns {PointsMap}
     */
    closeMapDialog() {
        if (this.dialog) {
            this.dialog.close();
        }
        return this;
    }

    /**
     * Dodaje nasłuch eventów
     * @param {string} event
     * @param {Function} handler
     * @returns {PointsMap}
     */
    on(event, handler) {
        this.events.addEventListener(this, {[event]: handler});
        return this;
    }

    /**
     * Zwraca wybrany punkt
     * @returns {null|Object}
     */
    getSelectedPoint() {
        return this.state.currentPoint;
    }

    /**
     * Callback po wybraniu punktu
     * @param {Function} handler
     * @returns {PointsMap}
     */
    onConfirm(handler) {
        return this.on('confirm:point', handler);
    }

    /**
     * Callback po zaznaczeniu punktu na mapie lub liście
     * @param {Function} handler
     * @returns {PointsMap}
     */
    onSelectPoint(handler) {
        return this.on('select:point', handler);
    }

    /**
     * Ustawia frazę wysdzukiwania
     * @param {string} searchQuery
     * @returns {PointsMap}
     */
    setSearchQuery(searchQuery) {
        this.state = {searchQuery};
        if (this.mapView) {
            this.mapView.performSearch();
        }
        return this;
    }

    /**
     * Ustawia dostępnych przewoźników
     * @param {string[]} availableProviders
     * @returns {PointsMap}
     */
    setAvailableProviders(availableProviders = []) {
        this.state = {availableProviders};
        return this;
    }

    /**
     * Ustawia wybranych przewoźników
     * @param {string[]} selectedProviders
     * @returns {PointsMap}
     */
    setSelectedProviders(selectedProviders = []) {
        this.state = {selectedProviders};
        return this;
    }

    /**
     * Ustawia pozycję mapy
     * @param {number} latitude
     * @param {number} longitude
     * @param {number} zoom
     * @returns {PointsMap}
     */
    setMapPosition(latitude, longitude, zoom) {
        this.state = {latitude, longitude, zoom};
        if (this.mapView && this.mapView.map) {
            this.mapView.map.setView([latitude, longitude], zoom);
        }
        return this;
    }

    /**
     * Zwraca pozycję środka mapy
     * @returns {[latitude, longitude, zoom]}
     */
    getMapPosition() {
        if (this.mapView && this.mapView.map) {
            const pos = this.mapView.map.getCenter();
            return [pos.lat, pos.lng, this.mapView.map.getZoom()];
        }
        return [this.state.latitude, this.state.longitude, this.state.zoom];
    }

    /**
     * Zwraca wymagane funkcje punktu
     * @returns {Array}
     */
    getPointFunction() {
        return this.state.functions;
    }

    /**
     * Ustawia wymagane funkcje punktu
     * @param functions
     * @returns {PointsMap}
     */
    setPointFunctionsFilter(functions) {
        if (isArray(functions)) {
            this.state = {functions}
        }
        return this;
    }

    /**
     * Zwraca nazwę wybranego przewoźnika
     * @param providerId
     * @return {*}
     */
    getProviderName(providerId) {
        return pointNameFormatter({provider: providerId});
    }

    /**
     * Niszczy widok
     */
    destroy() {
        if (this.dialog) {
            this.dialog.close();
            this.dialog = null;
        }

        if (this.mapView) {
            this.mapView.destroy();
            this.mapView = null;
        }
    }

}
