export const AVAILABLE_PROVIDERS = [
    'INPOST_PACZKOMAT',
    'POCZTEX_PUNKT',
    'PACZKA_W_RUCHU',
    'DPD_POINT',
    'FEDEX_DTR',
    'DHL_POINT',
    'UPS_AP_POINT_POINT',
    'INPOST_INTERNATIONAL'
];

export const NAMES = {
    INPOST_PACZKOMAT: 'InPost',
    POCZTEX_PUNKT: 'Pocztex',
    PACZKA_W_RUCHU: 'ORLEN Paczka',
    DPD_POINT: 'DPD Pickup',
    FEDEX_DTR: 'FedEx Punkt',
    DHL_POINT: 'DHL POP',
    UPS_AP_POINT_POINT: 'UPS Access Point',
    INPOST_INTERNATIONAL: 'InPost International'
};

export const TYPES_NAMES = {
    INPOST_PACZKOMAT: {
        PARCEL_LOCKER: 'Paczkomat',
        PARCEL_LOCKER_ZABKA: 'Paczkomat w Żabce',
        POP: 'PaczkoPunkt',
        POP_ZABKA: 'PaczkoPunkt w Żabce',
    },
    PACZKA_W_RUCHU: {
        APM: 'automat',
        PKN: 'stacja Orlen'
    },
    INPOST_INTERNATIONAL:{
        PARCEL_LOCKER: 'Paczkomat',
        POP: 'Punkt kurierski',
    }
}

const defaultPointIdFormatter = point => point.id;

const formatters = {
    POCZTEX_PUNKT: point => `${point.id} - ${point.name}`,
    DHL_POINT: point => `${point.id} - ${point.name}`,
    UPS_AP_POINT_POINT: point => `${point.id} - ${point.name}`,
};

export const pointIdFormatter = point => {
    const formatter = formatters[point.provider] || defaultPointIdFormatter;
    return formatter(point);
};

const defaultPointNameFormatter = point => NAMES[point.provider] || point.provider;

const namesFormatters = {};

export const pointNameFormatter = point => {
    const formatter = namesFormatters[point.provider] || defaultPointNameFormatter;
    return formatter(point);
};

const iconsFormatter = {
    INPOST_PACZKOMAT: point => {
        const type = String(point.type).toUpperCase();
        let typeName = TYPES_NAMES.INPOST_PACZKOMAT[type];
        if (point.requireApp) {
            typeName = 'Appkomat';
        }
        return `${typeName ? `<span class="pk-p-0 pk-px-2 pk-shadow-2 pk-border-radius-4 pk-label-INPOST_${type}">${typeName}</span> ` : ''}`
    },
    PACZKA_W_RUCHU: point => {
        const typeName = TYPES_NAMES.PACZKA_W_RUCHU[String(point.type).toUpperCase()];
        return `${typeName ? `<span class="pk-py-0 pk-px-1 pk-my-1 pk-shadow-2 pk-border-radius-4 pk-label-PACZKA_W_RUCHU">${typeName}</span>` : ''}`
    },
};

export const pointIconFormatter = point => {
    const formatter = iconsFormatter[point.provider];
    return formatter ? formatter(point) : '';
};
